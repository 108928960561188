<template>
  <div class="data-management">
    <div class="page-info">
      <div class="page-title">
        <p class="title-1">Data Management</p>
      </div>

      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-card outlined flat rounded="xl" class="pa-8">
      <v-tabs v-model="tab">
        <v-tab v-for="(tabItem, tabIndex) in tabList" :key="`tab ${tabItem} ${tabIndex}`">{{ tabItem }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-4">
        <v-tab-item v-for="(tabItem, tabIndex) in tabList" :key="`tabItem ${tabItem} ${tabIndex}`">
          <data-management-detail :_dataList="$store.state.code.codeMaster[tabItem].child" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import DataManagementDetail from "@/components/company/system/DataManagementDetail.vue";
export default {
  components: { DataManagementDetail },
  data() {
    return {
      loading: false,
      tab: 0,
      breadcrumbs: [
        {
          text: "System",
          disabled: true,
        },
        {
          text: "Data Information",
          disabled: true,
        },
      ],
    };
  },
  methods: {},
  computed: {
    tabList() {
      return Object.keys(this.$store.state.code.codeMaster);
    },
  },
  created() {},
};
</script>

<style></style>
