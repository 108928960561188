<template>
  <div class="data-management-detail d-flex">
    <div class="custom-table">
      <table>
        <thead>
          <tr>
            <th>Classification</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(data, dataIndex) in dataList"
            :key="`tr data ${dataIndex}`"
            :class="{ active: selectedDataKey === data.itemName, pointer: true }"
            @click="dataClicked(data)">
            <td>
              {{ data.itemName }}
            </td>
            <td>
              {{ data.cateType }}
            </td>
            <td>
              {{ data.itemDesc }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex flex-column ml-12 right-table" style="width: 400px">
      <div class="custom-table">
        <table>
          <thead>
            <tr>
              <th colspan="2">OPTIONAL DATA SET</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isSelectedParent">
              <td colspan="2" class="register pointer" @click="addShow = !addShow">
                <v-btn x-small color="secondary" :elevation="0">ADD</v-btn> NEW DATA
              </td>
            </tr>
            <tr v-if="addShow && isSelectedParent" class="add-option-row fw">
              <td colspan="2">
                <div class="d-flex align-center add-option">
                  <v-text-field
                    v-model="addText"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="addOption"></v-text-field>
                  <v-btn outlined :elevation="0" color="secondary" @click="addOption">ADD</v-btn>
                </div>
              </td>
            </tr>
            <tr v-if="includesChildDatas">
              <td colspan="2">
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :label="`SELECT ${childDatas[selectedDataKey].parent}`"
                  :items="dataList[childDatas[selectedDataKey].parent].options"
                  item-value="itemCd"
                  item-text="itemCd"
                  v-model="selectedParent"
                  @change="changedParent">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="custom-table y-scroll">
          <table style="border-top: none">
            <template v-if="hasValue(selectedDataOptions)">
              <draggable tag="tbody" animation="200" ghostClass="ghost" v-model="selectedDataOptions">
                <tr
                  v-for="(option, optionIndex) in selectedDataOptions"
                  style="cursor: move"
                  :key="`tr option ${option.itemCd} ${optionIndex}`">
                  <td style="width: 50px">
                    <v-checkbox v-model="option.checked" color="secondary" @change="checkedChanged" />
                  </td>
                  <td>
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                    {{ option.itemCd }}
                  </td>
                </tr>
              </draggable>
            </template>
          </table>
        </div>
      </div>

      <div class="d-flex mt-1">
        <v-btn text color="secondary" class="underline" :disabled="!isSelectedParent" @click="sort">
          SORT - {{ sortOption }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="secondary"
          :elevation="0"
          :loading="saveLoading"
          :disabled="!isSelectedParent"
          @click="includesChildDatas ? saveForChild() : save()">
          SAVE
        </v-btn>
        <v-btn
          class="ml-2"
          color="secondary"
          :elevation="0"
          :disabled="deleteDisabled"
          :loading="deleteLoading"
          @click="includesChildDatas ? deleteForChild() : deleteClicked()">
          DELETE
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { hasValue, toCamelCase, _deepCopy } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";
export default {
  props: {
    _dataList: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: { draggable },
  data() {
    return {
      dataList: {},
      childDatas: { BRAND: { parent: "BUYER" }, SIZE: { parent: "SIZE GROUP" } },
      selectedParent: "",
      selectedDataKey: "",
      selectedDataOptions: [],
      sortOption: "ASC",
      deleteDisabled: true,
      addShow: false,
      addText: "",
      saveLoading: false,
      deleteLoading: false,
    };
  },
  methods: {
    setDataList(_dataList) {
      this.selectedDataOptions = [];
      this.dataList = _deepCopy(_dataList);

      const keys = Object.keys(this.dataList);
      if (hasValue(this.dataList) && keys.length > 0) {
        keys.forEach(key => {
          const data = this.dataList[key];
          try {
            data.options = _deepCopy(
              this.$store.state.company.company[this.$store.getters.getCmpyUUID][toCamelCase(key)],
            );
            data.options?.forEach(o => (o.checked = false));
          } catch (ex) {
            //
          }
        });
        this.changedSelectedDataType(this.dataList[keys[0]]);
      }
    },
    dataClicked(data) {
      this.changedSelectedDataType(data);
    },
    changedSelectedDataType(data) {
      this.selectedParent = "";
      this.selectedDataKey = data.itemName;
      this.deleteDisabled = true;
      this.sortOption = "ASC";
      this.addShow = false;
      this.addText = "";

      if (this.includesChildDatas) {
        this.selectedDataOptions = null;
      } else this.selectedDataOptions = _deepCopy(data.options);
    },
    changedParent() {
      if (this.includesChildDatas) {
        if (hasValue(this.selectedParent)) {
          const options = this.dataList[this.childDatas[this.selectedDataKey].parent].options;
          const option = options.find(o => o.itemCd === this.selectedParent);
          if (hasValue(option)) {
            this.selectedDataOptions = option.itemDesc?.split("|$|").map(o => ({ itemCd: o, checked: false })) || [];
          }
        } else {
          this.selectedDataOptions = [];
        }
      }
    },
    checkedChanged() {
      this.deleteDisabled = this.selectedDataOptions?.every(o => o.checked === false);
    },
    sort() {
      this.sortOption = this.sortOption === "ASC" ? "DESC" : "ASC";
      this.selectedDataOptions.sort((a, b) => {
        const nameA = a.itemCd;
        const nameB = b.itemCd;
        if (nameA < nameB) return this.sortOption === "ASC" ? -1 : 1;
        if (nameA > nameB) return this.sortOption === "ASC" ? 1 : -1;
        return 0;
      });
    },
    addOption() {
      if (!hasValue(this.addText)) return;
      if (this.selectedDataOptions.some(o => o.itemCd === this.addText))
        return this.$toast.warning("Data is cannot be duplicated");

      this.selectedDataOptions.push({
        itemCd: this.addText,
        itemName: this.addText,
        checked: false,
        created: true,
      });

      // this.addShow = false;
      this.addText = "";
    },
    deleteClicked() {
      if (this.selectedDataOptions?.every(o => o.checked === false)) return;

      const commonCodeDeleteDto = this.selectedDataOptions
        .filter(o => o.checked && !o.created)
        .map(o => ({ itemCd: o.itemCd }));

      if (commonCodeDeleteDto.length > 0) {
        this.deleteLoading = true;
        this.$http
          .patch("/common-code", {
            commonCodeDeleteDto,
            cmpyUUID: this.$store.getters.getCmpyUUID,
            cateCd: toCamelCase(this.selectedDataKey),
          })
          .then(res => {
            if (res.data.status === 200) {
              const tempArr = this.selectedDataOptions.filter(o => o.checked && !o.created).map(o => o.itemCd);
              this.selectedDataOptions = this.selectedDataOptions.filter(o => !o.checked);

              const newOption = this.dataList[this.selectedDataKey].options.filter(o => !tempArr.includes(o.itemCd));

              this.dataList[this.selectedDataKey].options = newOption;
              this.$store.commit("company/changeCompanyCode", {
                cmpyUUID: this.$store.getters.getCmpyUUID,
                codeCategory: toCamelCase(this.selectedDataKey),
                code: newOption,
              });

              this.$toast.success("Successfully delete");
            } else codeHandler(res.data);
          })
          .finally(() => (this.deleteLoading = false));
      } else {
        this.selectedDataOptions = this.selectedDataOptions.filter(o => !o.checked);
        this.$toast.success("Successfully delete");
      }
    },
    deleteForChild() {
      if (this.selectedDataOptions?.every(o => o.checked === false)) return;

      const commonCodeChildDeleteList = this.selectedDataOptions
        .filter(o => o.checked && !o.created)
        .map(o => o.itemCd);

      if (commonCodeChildDeleteList.length > 0) {
        this.deleteLoading = true;
        this.$http
          .patch("/common-code/child", {
            cmpyUUID: this.$store.getters.getCmpyUUID,
            commonCodeChildDeleteList,
            commonCodeInsertDto: {
              itemCd: this.selectedParent,
              cateCd: toCamelCase(this.childDatas[this.selectedDataKey].parent),
            },
          })
          .then(res => {
            if (res.data.status === 200) {
              this.selectedDataOptions = this.selectedDataOptions.filter(o => !o.checked);

              const orgParentData = this.dataList[this.childDatas[this.selectedDataKey].parent].options.find(
                d => d.itemCd === this.selectedParent,
              );
              const orgArr = orgParentData.itemDesc.split("|$|");
              const newOption = orgArr.filter(o => !commonCodeChildDeleteList.includes(o));
              orgParentData.itemDesc = newOption.join("|$|");

              this.$store.commit("company/changeCompanyCodeDetail", {
                cmpyUUID: this.$store.getters.getCmpyUUID,
                codeCategory: toCamelCase(this.childDatas[this.selectedDataKey].parent),
                parentKey: this.selectedParent,
                itemDesc: newOption.join("|$|"),
              });

              this.$toast.success("Successfully delete");
            } else codeHandler(res.data);
          })
          .finally(() => (this.deleteLoading = false));
      } else {
        this.selectedDataOptions = this.selectedDataOptions.filter(o => !o.checked);
        this.$toast.success("Successfully delete");
      }
    },
    save() {
      const commonCodeInsertDtoList = [];
      for (let optionIndex = 0; optionIndex < this.selectedDataOptions.length; optionIndex++) {
        const option = this.selectedDataOptions[optionIndex];
        commonCodeInsertDtoList.push({
          itemCd: option.itemCd,
          itemName: option.itemName,
          itemOrd: optionIndex + 1,
          cateCd: toCamelCase(this.selectedDataKey),
          upperClass: this.selectedDataKey,
        });
      }

      this.saveLoading = true;
      this.$http
        .post("/common-code", { cmpyUUID: this.$store.getters.getCmpyUUID, commonCodeInsertDtoList })
        .then(res => {
          if (res.data.status === 200) {
            this.selectedDataOptions = commonCodeInsertDtoList;
            this.dataList[this.selectedDataKey].options = _deepCopy(commonCodeInsertDtoList);
            this.$store.commit("company/changeCompanyCode", {
              cmpyUUID: this.$store.getters.getCmpyUUID,
              codeCategory: toCamelCase(this.selectedDataKey),
              code: commonCodeInsertDtoList,
            });

            this.$toast.success("Successfully saved");
          } else codeHandler(res.data);
        })
        .finally(() => (this.saveLoading = false));
    },
    saveForChild() {
      const itemDesc = this.selectedDataOptions.map(o => o.itemCd).join("|$|");
      const commonCodeInsertDto = {
        itemCd: this.selectedParent,
        itemDesc,
        cateCd: toCamelCase(this.childDatas[this.selectedDataKey].parent),
      };

      this.saveLoading = true;
      this.$http
        .post("/common-code/child", { cmpyUUID: this.$store.getters.getCmpyUUID, commonCodeInsertDto })
        .then(res => {
          if (res.data.status === 200) {
            this.selectedDataOptions.forEach(o => {
              o.created = false;
            });

            const data = this.dataList[this.childDatas[this.selectedDataKey].parent].options.find(
              o => o.itemCd === this.selectedParent,
            );

            if (data) data.itemDesc = itemDesc;

            this.$store.commit("company/changeCompanyCodeDetail", {
              cmpyUUID: this.$store.getters.getCmpyUUID,
              codeCategory: toCamelCase(this.childDatas[this.selectedDataKey].parent),
              parentKey: this.selectedParent,
              itemDesc,
            });

            this.$toast.success("Successfully saved");
          } else codeHandler(res.data);
        })
        .finally(() => (this.saveLoading = false));
    },
    hasValue,
  },
  watch: {
    _dataList(newVal) {
      this.setDataList(newVal);
    },
  },
  computed: {
    includesChildDatas() {
      return Object.keys(this.childDatas).includes(this.selectedDataKey);
    },
    isSelectedParent() {
      //현재 데이터가 자식이 아닐 경우 true, 자식일 경우 부모 선택 여부 리턴
      return !this.includesChildDatas ? true : hasValue(this.selectedParent);
    },
  },
  created() {
    this.setDataList(this._dataList);
  },
};
</script>

<style lang="scss" scoped>
.custom-table .active {
  background: $blue4 !important;
}
</style>
